/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, ContactForm, Fullmap, FullmapWrap, FullmapCover } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader />

        <Column className="css-kbdxkd pb--80 pt--30" name={"uvod"} css={css`
        
    background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/144/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/144/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/144/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/144/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/144/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/144/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/144/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/144/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 mt--30 pb--0 pt--50" style={{"maxWidth":900}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" style={{"maxWidth":""}} content={"<span style='color: var(--color-dominant)'>Holandská 854/1, Brno</span>"}>
              </Title>

              <Text className="text-box" content={"<span style='color: var(--color-dominant)'>+420 797 811 2X0<br>info@vase-stranky.com<br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--4 mt--16 pb--0 pt--0" style={{"maxWidth":900}} columns={"4"}>
            
            <ColumnWrapper className="--left">
              
              <Text className="text-box" content={"<span style='color: var(--color-dominant)'>Po—Pá<br>7:00—15:00<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Text className="text-box" content={"<span style='color: var(--color-dominant)'>So—Ne<br>7:00—15:00<br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--30" name={"uvod"}>
          
          <ColumnWrap className="column__flex --center el--1 mt--16 pb--40 pt--0" style={{"maxWidth":900}} columns={"1"}>
            
            <ColumnWrapper className="mt--40">
              
              <Title className="title-box title-box--left" content={"Objednejte se nyní!"}>
              </Title>

              <ContactForm className="--shape2 --style1 mt--40 pl--02 pr--02" style={{"maxWidth":1126}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap name={"mapa"}>
          
          <FullmapWrap className="--full">
            
            <FullmapCover place={"Brno"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>

      </Layout>
    </ThemeWrapper>
  )
}